import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LayersIcon from '@material-ui/icons/Layers';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
  GroupIcon,
  SidebarSubmenu,
  SidebarSubmenuItem,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CategoryIcon from '@material-ui/icons/Category';
import BuildIcon from '@material-ui/icons/Build';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { MyGroupsSidebarItem } from '@backstage/plugin-org';
import { useApp } from '@backstage/core-plugin-api';
import MenuBookIcon from '@material-ui/icons/MenuBook';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="/home" text="Home" />
        <MyGroupsSidebarItem singularTitle="Team Hub" pluralTitle="Teams Hub" icon={GroupIcon} />
        <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog">
        <SidebarSubmenu title="Catalog">
          <SidebarSubmenuItem
            title="Domains"
            to="catalog?filters[kind]=domain"
            icon={useApp().getSystemIcon('kind:domain')}
          />
          <SidebarSubmenuItem
            title="Systems"
            to="catalog?filters[kind]=system"
            icon={useApp().getSystemIcon('kind:system')}
          />
          <SidebarSubmenuItem
            title="Components"
            to="catalog?filters[kind]=component"
            icon={useApp().getSystemIcon('kind:component')}
          />
          <SidebarSubmenuItem
            title="APIs"
            to="catalog?filters[kind]=api"
            icon={useApp().getSystemIcon('kind:api')}
          />
          <SidebarDivider />
          <SidebarSubmenuItem
            title="Infrastructure"
            to="catalog?filters[kind]=resource"
            icon={useApp().getSystemIcon('kind:resource')}
          />
          <SidebarDivider />
          <SidebarSubmenuItem
            title="Groups"
            to="catalog?filters[kind]=group"
            icon={useApp().getSystemIcon('kind:group')}
          />
          <SidebarSubmenuItem
            title="Users"
            to="catalog?filters[kind]=user"
            icon={useApp().getSystemIcon('kind:user')}
          />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="TechDocs" />
        <SidebarItem icon={MenuBookIcon} to="/learning-paths" text="Learning Paths" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create" />
        <SidebarItem icon={ListAltIcon} to="/create/tasks" text="Tasks" />
        <SidebarItem icon={LayersIcon} to="explore" text="Explore" />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarScrollWrapper>
          <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
          <SidebarItem icon={DeveloperBoardIcon} to="devtools" text="DevTools" />
          <SidebarItem icon={CardTravelIcon} to="toolbox" text="ToolBox" />
          <SidebarItem icon={BuildIcon} to="entity-validation" text="Validator" />
        <SidebarDivider />
          <SidebarItem icon={AnnouncementIcon} to="announcements" text="Announcements" />
          <SidebarItem icon={LiveHelpIcon} to="qeta" text="Ask for Help" />
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
